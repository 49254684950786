<template>
  <div class="colo-order page">
    <h1 class="page-title">
      {{ $t('title') }}
    </h1>
    <transition name="fade" mode="out-in">
      <div v-if="loading && !list.length" class="colo-order__loading">
        <base-loader />
      </div>
      <div v-else-if="list.length" class="colo-order__content">
        <page-block :title="$t('choise')" size="bigger">
          <pricelist-block :list="list">
            <template v-slot:item="{ item }">
              <pricelist-card
                :id="item.id"
                :title="item.name"
                :desc="sanitize(item.description)"
                :price="item.cost"
                :active="current && current.id === item.id"
                @click="routeTo(item)"
              />
            </template>
          </pricelist-block>
        </page-block>
        <transition name="fade" mode="out-in">
          <router-view></router-view>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import PricelistBlock from '@/components/Configurator/components/PricelistBlock';
import PricelistCard from '@/components/Configurator/components/PricelistCard';
import showErrorModal from '@/mixins/showErrorModal';
import gtmMixin from '@/mixins/gtmMixin';
export default {
  name: 'ColoOrder',
  components: { PricelistBlock, PricelistCard },
  mixins: [showErrorModal, gtmMixin],
  data() {
    return {
      configData: null,
      period: 1,
      cost: 0,
      costDetails: null,
      isCalculating: false,
      isSendingToBasket: false,
      isSendingToPay: false,
    };
  },
  computed: {
    list() {
      this.addDataLayerPriceData(this.$store.state.moduleColo.moduleColoOrder.list, 'Colo');
      return this.$store.state.moduleColo.moduleColoOrder.list;
    },
    loading() {
      return this.$store.state.moduleColo.moduleColoOrder.loading;
    },
    current() {
      return this.$store.getters['moduleColo/moduleColoOrder/current'] || this.currentItem;
    },
    id() {
      if (this.$route.path === '/colo/order/plans') {
        return;
      } else {
        return this.$route.params.id ? +this.$route.params.id : this.current.id;
      }
    },
    currentItem() {
      return this.list.find(i => i.id === +this.id);
    },
  },
  watch: {
    current: function () {
      this.period = 1;
    },
  },
  mounted() {
    this.fetchPricelist();
  },
  methods: {
    // api and logic methods
    routeTo(item) {
      this.clickToGtm(item, 'Colo');
      this.viewDetailGtm(item, 'Colo');
      this.setCurrent(item);
      this.fetchParams(item, this.period);
      this.updateConfig();
      this.$router.push({
        name: 'coloOrderDetails',
        params: { id: item.id, period: this.period },
      });
    },
    fetchPricelist() {
      const params = {
        show_metadata: 'on',
        newface: 'on',
      };
      this.$store.dispatch('moduleColo/moduleColoOrder/fetchPricelist', params);
    },
    fetchCalc(data) {
      return this.$store.dispatch('moduleColo/moduleColoOrder/fetchCalc', data);
    },
    setCurrent(item) {
      this.$store.dispatch('moduleColo/moduleColoOrder/setCurrent', item.id);
    },
    fetchParams(item, period) {
      const params = {};
      if (item) params.id = item.id;
      if (period) params.period = period;
      return this.$store.dispatch('moduleColo/moduleColoOrder/fetchParams', params);
    },
    updateConfig(data) {
      this.configData = { ...data };
    },
    sanitize(html) {
      let tmp = document.createElement('DIV');
      tmp.innerHTML = html;
      let res = tmp.textContent || tmp.innerText || '';
      res.replace('\u200B', ''); // zero width space
      res = res.trim();
      return res;
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "title": "Аренда физического сервера",
    "choise": "Выберите тариф",
    "additional": "Дополнительно",
    "summary": "Итого",
    "payfor": "при оплате за",
    "pay": "Оплатить",
    "order": "В корзину",
    "specs": {
      "disc": "{n} ГБ SSD",
      "mem": "{n} ГБ RAM",
      "ncpu": "{n} CPU"
    },
    "success": {
      "basket": "Заказ на {num} успешно добавлен в корзину",
      "pay": "Хостинг успешно создан, {num} списано с лицевого счета"
    },
    "needbalance": "Необходимо пополнить баланс"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.colo-order {
  &__loading {
    flexy(center, center);
    flex: 1 1 100%;
  }
  &__sum {
    flex: 0 0 100%;

    +breakpoint(ms-and-up) {
      flex: 0 0 auto;
      margin-right: 0.5rem;
    }
  }
  &__price {
    flexy(flex-start, center, wrap);

    &-text {
      margin-right: 0.5rem;

      +breakpoint(sm-and-up) {
        font-size: $font-size-bigger;
      }
    }
  }
  &__period {
    flex: 0 0 8.3rem;

    +breakpoint(sm-and-up) {
      flex: 0 0 10rem;
    }
  }

  &__actions {
    margin: 0.75rem -0.75rem -0.75rem;
    flexy(flex-start, center, wrap);
  }

  &__btn {
    margin: 0.75rem;

    +breakpoint(xs-only) {
      flex: 1 1 100%;
    }
  }
}
</style>
